<template>
  <main class="wrapper" v-if="AppActiveUser">
    <aside class="menu-side" :class="{'show': showMenuResponsive}">
      <Navigation />
    </aside>
    <aside class="main-content">
      <Header />
      <transition name="fade" mode="out-in" :duration="100">
        <router-view/>
      </transition>
    </aside>

    <div @click.prevent="$store.dispatch('toggleMenuResponsive', false)" class="overlay" :class="{'overlayshow': showMenuResponsive}"></div>
  </main>
</template>

<script>
import axios from '@/axios'
import Pusher from 'pusher-js'
import {Navigation, Header} from '../../components'
export default {
  components: {
    Navigation,
    Header
  },
  computed: {
    showMenuResponsive() { return this.$store.state.showMenuResponsive },
    AppActiveUser() { return this.$store.state.AppActiveUser },
    RoleIdUser() { return this.$store.state.RoleIdUser },
    permissions() { return this.$store.state.permissions },
  },
  methods: {
    subscribe() {
      let pusher = new Pusher(process.env.VUE_APP_KEY_PUSHER, { cluster: process.env.VUE_APP_CLUSTER_PUSHER })
      pusher.subscribe(`user.${this.AppActiveUser?.nik}`)
      pusher.bind('user.updated', ({user}) => {
        let profile = {
          ...user,
          role: user.roles[0].name,
          permissions: user.roles[0].permissions.map(permission => permission.name)
        }
        let roleId = user.roles[0].id
        this.$store.dispatch('updateUserInfo', profile)
        this.$store.dispatch('updateRoleId', roleId)
      })
      
      pusher.subscribe(`role.${this.RoleIdUser}`)
      pusher.bind('role.updated', ({role}) => {
        if(role) {
          let profile = {
            ...this.AppActiveUser,
            role: role.name,
            permissions: role.permissions.map(permission => permission.name)
          }
          let roleId = role.id
          this.$store.dispatch('updateUserInfo', profile)
          this.$store.dispatch('updateRoleId', roleId)
          // location.reload()
        }
      })
    },

    getAllPermissions() {
      if(!this.permissions){
        axios.get('/permissions')
          .then(res => {
            this.$store.dispatch('setPermissions', res.data.data)
          })
      }
    },

    getAllData() {
      this.getAllPermissions()
      this.subscribe()
    }
  },
  mounted() {
    this.getAllData()
  },
  watch: {
    "$route"() {
      this.$store.dispatch('toggleMenuResponsive', false)
    },
  }
}
</script>

<style>
.main__wrapper {
  display: flex;
  flex-direction: row;
}

.left__aside {
  flex: 0.2;
  background-color: gray;
}

.left__aside, .right__aside {
  height: 100vh;
}
</style>